import React, { useState, useEffect } from "react";
import GraphComponent from "./common/GraphComponent";
import { getGraphDataQuery } from "../services/api/graph";

const IngestionPointGraph: React.FC<{ ingestionId: string }> = ({ ingestionId }) => {
    const [graphData, setGraphData] = useState({ nodes: [], links: [] });
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchGraphData = async () => {
            try {
                const response = await getGraphDataQuery(
                    "MATCH (ip:IngestionPoint {id: $ingestionId})-[:ASSOCIATED_WITH*1..]->(related) RETURN ip, related",
                    { ingestionId }
                );
                setGraphData(response.data.graph);
            } catch (err: any) {
                setError(err.message || "Failed to fetch graph data");
            }
        };

        fetchGraphData();
    }, [ingestionId]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return <GraphComponent data={{ data: graphData }} />;
};

export default IngestionPointGraph;
