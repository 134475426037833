import React, { useState } from 'react';
import GraphLayout from '../layouts/GraphLayout';
import IngestionPointGraph from '../components/IngestionPointGraph';

const ComplexGraphPage: React.FC = () => {
    const [ingestionId, setIngestionId] = useState<string>('40'); // Replace with dynamic ingestion ID if needed
    const [error, setError] = useState<string | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIngestionId(e.target.value);
    };

    return (
        <GraphLayout>
            <div style={{ marginBottom: '20px' }}>
                <label htmlFor="ingestion-id" style={{ marginRight: '10px' }}>
                    Ingestion Point ID:
                </label>
                <input
                    id="ingestion-id"
                    type="text"
                    value={ingestionId}
                    onChange={handleInputChange}
                    placeholder="Enter ingestion point ID"
                />
            </div>
            {error ? (
                <div>Error: {error}</div>
            ) : (
                <IngestionPointGraph ingestionId={ingestionId} />
            )}
        </GraphLayout>
    );
};

export default ComplexGraphPage;
